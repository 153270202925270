<template>
  <div style="background: #fff;padding: 15px;" class="ml-goodList">
    <!-- tab切换 -->
    <el-tabs v-model="listState" @tab-click="filterFun">
      <el-tab-pane
        v-for="item in listStateList"
        :key="item.id"
        :label="item.value"
        :name="item.id+''"
      ></el-tab-pane>
    </el-tabs>

    <div class="filter-container">
      <!-- 代理等级 -->
      <div class="filter-item">
        <label class="label">关键字:</label>
        <el-select
          v-model="searchType"
          style="width: 120px;margin-right: 10px;"
          @change="keyTypeChange"
        >
          <el-option v-for="item in goodsSelect" :key="item.id" :label="item.type" :value="item.id"></el-option>
        </el-select>
        <el-input
          v-model="searchKey"
          placeholder="商品名称、编码、规格条形码"
          style="width:250px;margin-right: 10px;"
          clearable
          @keyup.enter.native="goodsFilter"
        ></el-input>
      </div>
    </div>
    <div class="filter-container">
      <div class="filter-item">
        <label class="label">销售类型:</label>
        <el-select
          v-model="saleType"
          style="width: 160px;margin-right: 10px;"
          clearable
          @change="saleChanges"
        >
          <el-option label="全部" :value="null"></el-option>
          <el-option v-for="item in saleSelect" :key="item.id" :label="item.type" :value="item.id"></el-option>
        </el-select>
      </div>

      <div class="filter-item">
        <label class="label" v-if="saleType==1">销售方式:</label>
        <el-select
          v-if="saleType==1"
          v-model="saleStyle"
          style="width: 250px;margin-right: 10px;"
          clearable
          @change="saleChanges"
        >
          <el-option label="全部" :value="null"></el-option>
          <el-option
            v-for="item in saleStylelist"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <div class="filter-item">
        <label class="label">商品分组:</label>
        <el-select
          v-model="goodsType"
          placeholder="请选择"
          style="width: 300px;margin-right: 10px;"
          multiple
          filterable
          clearable
        >
          <el-option label="全部" :value="-1"></el-option>
          <el-option label="未分组" :value="0"></el-option>
          <el-option
            v-for="item in productSortList"
            :key="item.Id"
            :label="item.GroupName"
            :value="item.Id"
          ></el-option>
        </el-select>
      </div>

      <div class="filter-item">
        <label class="label">商品品牌:</label>
        <el-select v-model="brandType" placeholder="请选择" style="width: 250px;" clearable>
          <el-option label="全部" :value="null"></el-option>
          <el-option
            v-for="item in productList"
            :key="item.Id"
            :label="item.BrandName"
            :value="item.Id"
          ></el-option>
        </el-select>
      </div>

      <div class="filter-item" v-if="ishowIsOpenSupplierProduct">
        <label class="label">商品类型:</label>
        <el-select v-model="goodsSorts" placeholder="请选择" style="width: 200px;" clearable>
          <el-option label="全部" :value="null"></el-option>
          <el-option
            v-for="item in goodsSortsList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="filter-container">
      <!-- <div style="margin-top: 10px;align-items: center;display: flex;"> -->
      <div class="filter-item">
        <label class="label">价格区间:</label>
        <el-input v-model="priceMin" placeholder="最小价格" style="width: 130px;" clearable></el-input>
        <span>~</span>
        <el-input
          v-model="priceMax"
          placeholder="最大价格"
          style="width: 130px;margin:0 10px;"
          clearable
        ></el-input>
      </div>
      <div class="filter-item">
        <label class="label">销量区间:</label>
        <el-input v-model="saleMin" placeholder="最小销量" style="width: 130px;" clearable></el-input>
        <span>~</span>
        <el-input
          v-model="saleMax"
          placeholder="最大销量"
          style="width: 130px;margin:0 10px;"
          clearable
        ></el-input>
      </div>
      <div class="filter-item">
        <el-button type="primary" @click="goodsFilter">查询</el-button>
        <buttonPermissions :datas="'addProduct'">
          <el-button type="primary" @click="eidtGroup({})" style="margin-left:10px">发布商品</el-button>
        </buttonPermissions>
        <buttonPermissions :datas="'downloadProduct'">
          <el-button @click="exportFun" style="margin-left:10px">导出</el-button>
        </buttonPermissions>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table-container">
      <el-table
        :data="groupData"
        style="width: 100%;"
        v-loading="loading"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
        empty-text="查询不到相关商品"
        @cell-mouse-enter="mousereeEnter"
        @cell-mouse-leave="mouserLeave"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="商品" width="450">
          <template slot-scope="scope">
            <div class="product-info" style="align-items:flex-start">
              <img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl+'@!cut64'" />
              <svg-icon v-else icon-class="noImgIcon" />
              <div style="min-height: auto;">
                <div style="display:flex;justify-content:space-between;align-items:flex-start;">
                  <!-- <div style="margin-right: 10px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;"> -->
                  <div
                    style="color: #f00;font-size: 12px;width:40px"
                    v-if="scope.row.ProductType==1"
                  >[组合]</div>
                  <!-- </div> -->
                  <div style="width:280px;">
                    <pre
                      style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;"
                    >{{scope.row.Name}}</pre>
                  </div>
                  <!-- v-if="scope.row.ProductType==0" -->
                  <i
                    class="el-icon-edit-outline"
                    @click="productEdit(scope.row)"
                    v-if="scope.row.isShoweite"
                  ></i>
                </div>
                <div
                  style="color:#909399"
                  v-if="!scope.row.IsSupplierProduct"
                >{{scope.row.ProductNo}}</div>

                <el-tag
                  type="danger"
                  size="small"
                  style="margin-top: 10px;"
                  v-if="scope.row.IsOpenSupplierProduct&&ishowIsOpenSupplierProduct"
                >已发布到供货市场</el-tag>
                <el-tag
                  type="danger"
                  size="small"
                  style="margin-top: 10px;"
                  v-if="scope.row.IsSupplierProduct"
                >分销商品</el-tag>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="访问量">
          <template slot-scope="scope">
            <div>访客数:{{scope.row.VisitCount}}</div>
            <div>浏览量:{{scope.row.ViewCount}}</div>
          </template>
        </el-table-column>

        <el-table-column prop="ProductPrice" label="价格" sortable>
          <template slot-scope="scope">
            <div style="display:flex;justify-content:flex-start;align-items:center;">
              <div
                style="white-space: nowrap;margin-right: 5px;color:#F56C6C;"
              >￥{{scope.row.ProductPrice}}</div>
              <!-- v-if="scope.row.ProductType==0" -->
              <i
                class="el-icon-edit-outline"
                @click="priceEdit(scope.row)"
                v-if="scope.row.isShoweite"
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Stock" label="库存" sortable>
          <template slot-scope="scope">
            <div style="display:flex;justify-content:flex-start;align-items:center;">
              <div style="white-space: nowrap;margin-right: 5px;">{{scope.row.Stock}}</div>
              <i
                class="el-icon-edit-outline"
                @click="stockEdit(scope.row)"
                v-if="!scope.row.IsSupplierProduct&&scope.row.isShoweite&&scope.row.ProductType==0"
              ></i>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="SellCount" sortable label="销量"></el-table-column>
        <el-table-column prop="AddTime" label="创建时间"></el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="scope">
            <buttonPermissions :datas="'reviseProduct'">
              <el-button type="text" @click="eidtGroup(scope.row)" class="pointers">编辑</el-button>
            </buttonPermissions>
            <buttonPermissions :datas="'onOffSale'">
              <el-button
                type="text"
                style="margin-left: 15px;"
                v-if="listState==3"
                @click="displyFun(scope.row,1)"
                class="pointers"
              >上架</el-button>
              <el-button
                type="text"
                style="margin-left: 15px;"
                v-if="listState==1 ||listState==2"
                @click="displyFun(scope.row,2)"
                class="pointers"
              >下架</el-button>
            </buttonPermissions>
            <buttonPermissions :datas="'productTg'">
              <el-button
                type="text"
                @click="weiCodeFun(scope.row.ProductId)"
                style="margin-left: 15px;"
                class="pointers"
              >推广</el-button>
            </buttonPermissions>

            <el-dropdown style="margin-left:15px">
              <span class="el-dropdown-link">
                <span style="color:#409EFF;">更多</span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <buttonPermissions :datas="'virtualSales'">
                    <span style="color:#409EFF;" @click="setVisal(scope.row)">虚拟销量</span>
                  </buttonPermissions>
                </el-dropdown-item>

                <el-dropdown-item
                  v-if="(listState==1||listState==2)&&ishowIsOpenSupplierProduct&&scope.row.IsOpenSupplierProduct"
                >
                  <button-permissions :datas="'batchRemovemark'">
                    <span
                      style="color:#409EFF;"
                      @click="singleChangMark(scope.row,0)"
                      v-loading="changeloading"
                    >从供货市场移除</span>
                  </button-permissions>
                </el-dropdown-item>

                <el-dropdown-item
                  v-if="(listState==1||listState==2)&&ishowIsOpenSupplierProduct&&scope.row.ProductType!=1&&(!scope.row.IsSupplierProduct)&&(!scope.row.IsOpenSupplierProduct)"
                >
                  <button-permissions :datas="'batchPushmark'">
                    <span
                      style="color:#409EFF;"
                      @click="singleChangMark(scope.row,1)"
                      v-loading="changeloading"
                    >发布到供货市场</span>
                  </button-permissions>
                </el-dropdown-item>

                <el-dropdown-item>
                  <buttonPermissions :datas="'MeterialMangebtn'">
                    <span
                      style="color:#409EFF;display: inline-block;width:80px;"
                      @click="MaterialManage(scope.row)"
                      class="pointers"
                    >素材管理</span>
                  </buttonPermissions>
                </el-dropdown-item>
                <el-dropdown-item>
                  <buttonPermissions :datas="'deleteProduct'">
                    <span
                      style="color:#f00;display: inline-block;width:80px;"
                      @click="deleteChose(scope.row)"
                      class="pointers"
                    >删除</span>
                  </buttonPermissions>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <div
              style="color: rgb(245, 108, 108);"
              v-if="scope.row.IsSupplierMallClose&&scope.row.IsSupplierProduct&&listState==3"
            >
              <span class="el-icon-warning-outline"></span>
              商品销售信息有变更
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div style="display:flex;justify-content:space-between;align-items:center;">
        <div
          style="display:flex;font-size: 12px;color: #606266;align-items:center;margin:20px 0 0 13px;"
        >
          <el-checkbox @change="tabCheckedChange" v-model="checked">当前页全选</el-checkbox>
          <buttonPermissions :datas="'batchDelete'">
            <el-button size="mini" style="margin-left: 20px;" @click="deleteBatchs">批量删除</el-button>
          </buttonPermissions>

          <buttonPermissions :datas="'batchSale'">
            <el-button
              size="mini"
              style="margin-left: 10px;"
              @click="addBatchs(1)"
              v-if="listState==1 ||listState==2"
            >批量下架</el-button>
            <el-button
              size="mini"
              style="margin-left: 10px;"
              @click="addBatchs(2)"
              v-if="listState==3"
            >批量上架</el-button>
          </buttonPermissions>

          <el-dropdown size="mini" style="margin-left: 10px;">
            <el-button size="mini">
              更多
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="(listState==1||listState==2)&&ishowIsOpenSupplierProduct">
                <buttonPermissions :datas="'batchRemovemark'">
                  <div @click="changeMark(0)" v-loading="changeloading">从供货市场移除</div>
                </buttonPermissions>
              </el-dropdown-item>
              <el-dropdown-item v-if="(listState==1||listState==2)&&ishowIsOpenSupplierProduct">
                <buttonPermissions :datas="'batchPushmark'">
                  <div @click="changeMark(1)" v-loading="changeloading">发布到供货市场</div>
                </buttonPermissions>
              </el-dropdown-item>
              <el-dropdown-item>
                <div @click="moneyBatchs">收益与提成</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div @click="sortsBatchs">设置分组</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <buttonPermissions :datas="'batchVirtualSales'">
                  <div @click="bathVisale">虚拟销量</div>
                </buttonPermissions>
              </el-dropdown-item>
              <!-- <el-dropdown-item>
								<div @click="addcarBatchs">加入购物车</div>
              </el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-pagination
          v-if="Total"
          style="margin:20px 0;float:right;"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Total"
        ></el-pagination>
      </div>
    </div>

    <!-- 商品名称编辑的弹框 -->
    <div class="nameboxClass">
      <el-dialog :visible.sync="editNameVisable" width="50%" :title="eidiTnames">
        <div style="border-top:1px solid #ddd;margin-bottom: 15px;"></div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="商品名称:" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="最多输入100个字"
              maxlength="100"
              clearable
              style="width:350px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="商品编码:"
            :prop="IsSupplierProduct?'nocheck':'code'"
            v-if="!IsSupplierProduct"
          >
            <el-input
              maxlength="50"
              @input="codeChangefun"
              v-model="ruleForm.code"
              size="small"
              style="width:350px"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center;margin-top:30px;">
          <el-button
            type="primary"
            style="margin-right:10px;width: 120px;"
            @click="submitForm('ruleForm')"
          >保存</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 单品销售商品价格编辑的弹框 -->
    <el-dialog :visible.sync="editPriceVisable" width="800px" title="编辑价格" v-loading="specLoading">
      <div
        style="margin-bottom: 20px;border-top:1px solid #ddd;padding-top: 15px;"
      >商品:{{productNames}}</div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <div class="setPricebos">
          <el-table
            :data="ruleForm.specData"
            border
            style="width: 100%;"
            v-if="ruleForm.specData.length"
            :row-key="speceKey"
          >
            <el-table-column prop="SpecValue" :label="SpecTitle"></el-table-column>
            <el-table-column prop="SpecValue2" :label="SpecTitle2" v-if="SpecTitle2"></el-table-column>
            <el-table-column label="价格">
              <template slot-scope="scope">
                <!-- 	<input v-model="scope.row.Price" style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
                type="number" @keydown="handleInput2">-->
                <!-- (ruleForm.IsSetMixUnitPrice==1)?('specData.' + scope.$index + '.Price'):'nocheck' -->
                <el-form-item
                  :inline-message="true"
                  :prop="('specData.' + scope.$index + '.Price')"
                  :rules="rules.Price"
                >
                  <el-input
                    clearable
                    @input="tablepriceChange(scope.row)"
                    v-model="scope.row.Price"
                    style="width:100px;"
                    onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>

            <!-- 分销商品 -->
            <el-table-column
              prop="SupplierMallPrice"
              label="供货价"
              v-if="priceEditdata.IsSupplierProduct"
            ></el-table-column>
            <el-table-column
              prop="RetailPriceMin"
              label="建议零售价"
              v-if="priceEditdata.IsSupplierProduct"
            >
              <template
                slot-scope="scope"
              >{{scope.row.RetailPriceMin}} ~ {{scope.row.RetailPriceMax}}</template>
            </el-table-column>
            <el-table-column prop="suppierProfit" label="利润" v-if="priceEditdata.IsSupplierProduct"></el-table-column>
          </el-table>
        </div>

        <!-- 单规格商品 -->
        <div v-if="!ruleForm.specData.length">
          <el-form-item v-if="priceEditdata.IsSupplierProduct">
            <span>供货价：</span>
            <el-input v-model="ruleForm.SupplierMallPrice" style="width:150px;" disabled></el-input>
            <span style="margin-left: 15px">建议零售价：</span>
            <el-input v-model="ruleForm.RetailPrice" style="width:150px;" disabled></el-input>
            <span style="margin-left: 15px">利润：</span>
            <el-input v-model="ruleForm.suppierProfit" style="width:150px;" disabled></el-input>
          </el-form-item>

          <div class="filter-container">
            <div class="filter-item" style="margin-right:-7px">
              <el-form-item label="价格：" required></el-form-item>
            </div>
            <div class="filter-item">
              <el-form-item label prop="singleprice">
                <el-input
                  @input="sintablepriceChange"
                  v-model="ruleForm.singleprice"
                  clearable
                  style="width:200px;"
                  onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>

        <div style="text-align: center;margin-top:30px;">
          <el-button
            type="primary"
            style="width: 120px;"
            :loading="loading"
            @click="savePriceBtn('ruleForm')"
          >保存</el-button>
        </div>
      </el-form>
    </el-dialog>

    <!-- 组合商品价格编辑弹框 -->
    <el-dialog :visible.sync="contacteditPriceVisable" width="1100px" title="编辑价格">
      <div
        style="margin-bottom: 20px;border-top:1px solid #ddd;padding-top: 15px;"
      >商品:{{productNames}}</div>
      <el-form :model="contactRuleForm" :rules="rules" ref="contactRuleForm">
        <el-form-item label="价格设置">
          <el-radio-group v-model="contactRuleForm.IsSetMixUnitPrice" @change="pricesetChange">
            <el-radio :label="0">设置组合商品总价</el-radio>
            <el-radio :label="1">设置子商品单价</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="组合明细">
          <el-table
            :data="contactRuleForm.ProductMixList"
            style="width:1100px"
            :row-key="keyFun1"
            :loading="mixListLoading"
          >
            <el-table-column prop="Name" label="子商品"></el-table-column>
            <el-table-column prop="SpecValue" label="规格">
              <template slot-scope="scope">
                <span v-if="scope.row.SpecValue">
                  <span>{{scope.row.SpecValue}}</span>
                </span>
                <span v-else>默认规格</span>
                <span v-if="scope.row.SpecValue2">，{{scope.row.SpecValue2}}</span>
              </template>
            </el-table-column>

            <el-table-column label="组合内售价" width="200">
              <template slot-scope="scope">
                <el-form-item
                  :inline-message="true"
                  :prop="contactRuleForm.IsSetMixUnitPrice==1?('ProductMixList.' + scope.$index + '.UnitPrice'):'nocheck'"
                  :rules="contactRuleForm.IsSetMixUnitPrice==1?rules.UnitPrice:rules.nocheck"
                >
                  <el-input
                    @input="salePriceFun"
                    :disabled="contactRuleForm.IsSetMixUnitPrice==0?true:false"
                    v-model="scope.row.UnitPrice"
                    style="width:100px;"
                    onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>

            <el-table-column prop="name" label="组合内数量" width="200">
              <template slot-scope="scope">
                <el-form-item
                  :inline-message="true"
                  :prop="'ProductMixList.' + scope.$index + '.ProductCount'"
                  :rules="rules.ProductCount"
                >
                  <input
                    @input="contactChange"
                    v-model="scope.row.ProductCount"
                    style="margin:0 15px;padding:0 10px;width:100px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
                    oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"
                    type="number"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="Price" label="价格"></el-table-column>
            <el-table-column prop="Stock" label="库存"></el-table-column>
          </el-table>
        </el-form-item>

        <el-form-item
          label="价格"
          :prop="contactRuleForm.IsSetMixUnitPrice==0?'contactOnePrice':'nocheck'"
          :rules="contactRuleForm.IsSetMixUnitPrice==0?rules.contactOnePrice:rules.nocheck"
        >
          <input
            :disabled="contactRuleForm.IsSetMixUnitPrice==1?true:false"
            v-model="contactRuleForm.contactOnePrice"
            style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
            @keydown="handleInput2"
            type="number"
          />
        </el-form-item>

        <div style="text-align: center;margin:30px 0;">
          <el-button
            type="primary"
            style="width: 120px;"
            :loading="contactLoading"
            @click="savecontacBtn('contactRuleForm')"
          >保存</el-button>
        </div>
      </el-form>
    </el-dialog>

    <!-- 商品库存编辑的弹框 -->
    <el-dialog :visible.sync="stockVisble" width="800px" title="商品出/入库">
      <div
        style="margin-bottom: 20px;border-top:1px solid #ddd;padding-top: 15px;"
      >商品:{{productNames}}</div>
      <el-table
        :data="ruleForm.specData"
        border
        style="width: 100%;"
        v-if="ruleForm.specData.length"
      >
        <el-table-column prop="SpecValue" :label="SpecTitle"></el-table-column>
        <el-table-column prop="SpecValue2" :label="SpecTitle2" v-if="SpecTitle2"></el-table-column>
        <el-table-column label="库存">
          <template slot-scope="scope">
            <input
              v-model="scope.row.Stock"
              style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
              oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"
              type="number"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" v-if="!ruleForm.specData.length">
        <el-form-item label="库存:" prop="singledesc" required>
          <input
            v-model="ruleForm.singledesc"
            style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
            oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"
            type="number"
          />
        </el-form-item>
      </el-form>
      <div style="text-align: center;margin-top:30px;">
        <el-button
          type="primary"
          style="width: 120px;"
          :loading="btnLoading"
          @click="savestockBtn"
        >保存</el-button>
      </div>
    </el-dialog>

    <!-- 删除按钮 -->
    <el-dialog title="提示" :visible.sync="deleteDialogShow" width="40%" center>
      <div style="text-align: center;" v-if="!currentItemGoods">是否确认删除选中的{{slectgoodNum}}个商品？删除后不可恢复</div>
      <!-- 展示多商品或多规格信息 -->
      <div v-else>
        <div
          style="line-height: 25px;width: 100%;"
          v-for="(item,index) in currentItemGoods"
          :key="index"
        >{{item}}</div>
        <div style="color: #F56C6C;line-height: 25px;">{{currentnum}}</div>
        <!-- <div v-for="(item,index) in currentItemGoods" :key="index" style="margin-bottom: 20px;">{{item.name}}</div>
        <div style="color: #F56C6C;">共计{{slectgoodNum}}个商品，作为组合商品销售，不支持删除。若要删除，请先至组合商品中删除相应商品</div>-->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="width: 120px;"
          @click="deleteDialogShow = false"
          v-if="!currentItemGoods"
        >取 消</el-button>
        <el-button
          style="width: 120px;margin-left: 30px;"
          type="primary"
          v-if="currentItemGoods"
          @click="deleteDialogShow=false"
        >我知道了</el-button>
        <el-button
          style="width: 120px;margin-left: 30px;"
          type="primary"
          v-else
          @click="sureDelect"
          :loading="btnLoading"
        >
          确
          定
        </el-button>
      </span>
    </el-dialog>

    <!-- 收益与提成的弹框 -->
    <el-dialog title="收益与提成" :visible.sync="moneyDialogShow" width="1100px" @close="moneyClosefun">
      <el-form
        :model="ruleFormMoney"
        :rules="rules"
        ref="ruleFormMoney"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="店员提成">
          <el-radio-group v-model="ruleFormMoney.EmployeeSellRewardType">
            <el-radio :label="2">按百分比发放</el-radio>
            <el-radio :label="1">按固定金额发放</el-radio>
          </el-radio-group>

          <div v-show="ruleFormMoney.EmployeeSellRewardType==2">
            <el-form-item label-width="0">
              商品实付金额
              <span style="margin-left:7px">*</span>
              <el-input
                v-model="ruleFormMoney.EmployeeSellRewardRate"
                placeholder="0"
                style="width:200px;margin:0 15px"
                onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
              ></el-input>%
            </el-form-item>
          </div>

          <div v-show="ruleFormMoney.EmployeeSellRewardType==1">
            <el-form-item label-width="0">
              商品购买数量
              <span style="margin-left:7px">*</span>
              <el-input
                v-model="ruleFormMoney.EmployeeSellRewardMoney"
                placeholder="0"
                style="width:200px;margin:0 7px"
                onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
              ></el-input>元/件
            </el-form-item>
          </div>
        </el-form-item>

        <el-form-item label="推手收益" v-if="ruleFormMoney.ProductSellRewardList.length">
          <el-radio-group v-model="ruleFormMoney.MemberSellRewardType">
            <el-radio :label="2">按百分比发放</el-radio>
            <el-radio :label="1">按固定金额发放</el-radio>
          </el-radio-group>
          <div v-show="ruleFormMoney.MemberSellRewardType==2" style="margin-top:10px;">
            <el-table
              :data="ruleFormMoney.ProductSellRewardList"
              style="width:1100px"
              :loading="loading"
            >
              <el-table-column prop="DistributRoleName" label="级别"></el-table-column>
              <el-table-column prop="name" label="自营销售收益">
                <template slot-scope="scope">
                  <el-form-item label-width="0">
                    商品实付金额
                    <span style="margin:0 7px">*</span>
                    <el-input
                      v-model="scope.row.SellRewardRate"
                      placeholder="0"
                      style="width:80px;"
                      onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
                    ></el-input>%
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="直推销售收益">
                <template slot-scope="scope">
                  <el-form-item label-width="0">
                    商品实付金额
                    <span style="margin:0 7px">*</span>
                    <el-input
                      v-model="scope.row.RecommendSellRewardRate"
                      placeholder="0"
                      style="width:80px;"
                      onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
                    ></el-input>%
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-show="ruleFormMoney.MemberSellRewardType==1" style="margin-top:10px;">
            <el-table
              :data="ruleFormMoney.ProductSellRewardList"
              style="width:1100px"
              :loading="loading"
            >
              <el-table-column prop="DistributRoleName" label="级别"></el-table-column>
              <el-table-column prop="name" label="自营销售收益">
                <template slot-scope="scope">
                  <el-form-item
                    label-width="0"
                    :inline-message="true"
                    :prop="ruleFormMoney.MemberSellRewardType==1?('ProductSellRewardList.' + scope.$index + '.SellRewardMoney'):'nocheck'"
                    :rules="ruleFormMoney.MemberSellRewardType==1?rules.SellRewardMoney:rules.nocheck"
                  >
                    商品购买数量
                    <span style="margin:0 7px">*</span>
                    <el-input
                      placeholder="0"
                      v-model="scope.row.SellRewardMoney"
                      style="width:80px;"
                      onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
                    ></el-input>元/件
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="address" label="直推销售收益">
                <template slot-scope="scope">
                  <el-form-item
                    label-width="0"
                    :inline-message="true"
                    :prop="ruleFormMoney.MemberSellRewardType==1?('ProductSellRewardList.' + scope.$index + '.RecommendSellRewardMoney'):'nocheck'"
                    :rules="ruleFormMoney.MemberSellRewardType==1?rules.RecommendSellRewardMoney:rules.nocheck"
                  >
                    商品购买数量
                    <span style="margin:0 7px">*</span>
                    <el-input
                      placeholder="0"
                      v-model="scope.row.RecommendSellRewardMoney"
                      style="width:80px;"
                      onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
                    ></el-input>元/件
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </el-form>

      <div style="text-align: center;margin:0 20px">
        <el-button type="primary" @click="saveMoneyFun">保存</el-button>
      </div>
    </el-dialog>

    <!-- 修改分组的弹框 -->
    <el-dialog title="批量修改分组" :visible.sync="eiditeSortVisible" width="800px">
      <label class="label">关键字:</label>
      <el-input v-model="sortSearchKey" placeholder="分组名称" style="width:250px;" clearable></el-input>
      <el-button type="primary" @click="sortsfunFilter" style="margin-left:20px">查询</el-button>

      <!-- 分组名称 -->
      <div class="sorts-names" v-loading="sortNaming">
        <div class="sorttable-top">分组名称</div>
        <div class="sortTable-body" v-for="(item,indexsort) in bathSortDatas" :key="indexsort">
          <el-checkbox
            :indeterminate="item.isIndeterminate"
            v-model="item.checkAll"
            @change="handleCheckAllChange(item)"
          >{{item.GroupName}}</el-checkbox>
        </div>
      </div>

      <div style="text-align: center;margin: 30px 0 20px;">
        <el-button @click="eiditeSortVisible=false" style="width:120px;margin-right:15px">关闭</el-button>
        <el-button
          type="primary"
          @click="sureEditSortfun"
          style="width:120px"
          :loading="sortsLoading"
        >确认修改</el-button>
      </div>
    </el-dialog>

    <!-- 推广的弹框 -->
    <batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel"></batch-cods>

    <!-- 加入购物车弹框 -->
    <el-dialog title="批量设置加入购物车" :visible.sync="batchaddCarVisible" width="700px">
      <el-form
        :model="carruleForm"
        :rules="rules"
        ref="carruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="加入到购物车:" prop="IsAddCart">
          <el-radio-group v-model="carruleForm.IsAddCart">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
          <p style="color:rgb(128, 127, 127);font-size: 14px;line-height: 25px;">
            关闭加入购物车后，商品只能直接购买，不可在购物车内结算；
            分销礼包商品不适用该配置，批量设置时我们将自动过滤相应数据
          </p>
        </el-form-item>

        <div style="text-align: center;margin: 40px 0 20px;">
          <el-button @click="batchaddCarVisible=false" style="width:120px;margin-right:15px">关闭</el-button>
          <el-button
            type="primary"
            @click="sureCarfun('carruleForm')"
            style="width:120px"
            :loading="sortsLoading"
          >确认修改</el-button>
        </div>
      </el-form>
    </el-dialog>
    <div class="virtualsalesBox">
      <!-- 虚拟销量的弹框 -->
      <el-dialog
        :title="virtualsalesTitle"
        :visible.sync="virtualsalesVisible"
        width="700px"
        @closed="virtualsaleClose"
      >
        <p
          v-if="productNameishow"
          style="font-size: 14px;line-height: 22px;"
        >商品：{{virtualsalesProduct}}</p>
        <p>
          虚拟销量：
          <input
            v-model="virtualsalesNum"
            style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
            oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"
            type="number"
          />
        </p>
        <p style="color: #908E8E;margin-left: 73px;">虚拟销量设置成功后，商品详情页的销量将变更为该数值；虚拟销量仅作商城前台展示</p>

        <p>
          销量系数：
          <input
            v-model="minFactor"
            placeholder="最小系数"
            style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
            oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"
            type="number"
          />
          ~
          <input
            v-model="maxFactor"
            placeholder="最大系数"
            style="padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
            oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')"
            type="number"
          />
        </p>

        <p style="color: #908E8E;margin-left: 73px;">例：设置系数1~5，客户购买商品，商品虚拟销量将在购买数量上增加1~5内的随机数</p>

        <div style="text-align: center;margin: 30px 0 30px;">
          <el-button
            type="primary"
            @click="virtualSave"
            style="width:120px"
            :loading="virtualLoading"
          >保 存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  productList,
  productOpen,
  productDelete,
  productBrandList,
  productGroupList,
  productSpecGet,
  productChangeNameAndNo,
  productChangePrice,
  productSpeChangeStock,
  productBatchOpen,
  productBatchDelete,
  distributorRoleFilterBoxList,
  changeSellReward,
  batchChangeGroupInit,
  batchChangeGroup,
  productBatchAddCart,
  changeVirtualSalesInit,
  changeVirtualSales,
  batchChangeVirtualSales,
  changeSupplierState,
  batchChangeSupplierState,
} from "@/api/goods";
import config from "@/config/index";
import buttonPermissions from "@/components/buttonPermissions";
import batchCods from "@/components/bathCodes";
export default {
  components: {
    buttonPermissions,
    batchCods,
  },
  data() {
    var checkSellRewardMoney = (rule, value, callback) => {
      // if (!value && value != '0') {
      // 	return callback(new Error('请完善收益或提成信息'));
      // } else {
      if (value > 100000000) {
        return callback(new Error("数值超出限制，请重新设置"));
      } else {
        return callback();
      }
      // }
    };
    var checkRecommendSellRewardMoney = (rule, value, callback) => {
      // if (!value && value != '0') {
      // 	return callback(new Error('请完善收益或提成信息'));
      // } else {
      if (value > 100000000) {
        return callback(new Error("数值超出限制，请重新设置"));
      } else {
        return callback();
      }
      // }
    };
    var checkno = (rule, value, callback) => {
      return callback();
    };
    var checkUnitPrice = (rule, value, callback) => {
      if (!value || value == "") {
        return callback(new Error("请设置子商品单价"));
      } else {
        return callback();
      }
    };
    var checkProductCount = (rule, value, callback) => {
      if (!value || value == "" || value == "0") {
        return callback(new Error("请完输入子商品数量"));
      } else {
        return callback();
      }
    };
    var PriceCheck = (rule, value, callback) => {
      let arrIndex = rule.field.split(".")[1];
      let singRetailPriceMin = this.ruleForm.specData[arrIndex].RetailPriceMin;
      let singRetailPriceMax = this.ruleForm.specData[arrIndex].RetailPriceMax;
      if (!value || value == "") {
        return callback(new Error("请设置价格"));
      }
      if (
        (value < singRetailPriceMin || value > singRetailPriceMax) &&
        this.priceEditdata.IsSupplierProduct
      ) {
        return callback(
          new Error(
            "售价请设置在" +
              singRetailPriceMin +
              "至" +
              singRetailPriceMax +
              "之间"
          )
        );
      } else {
        return callback();
      }
    };
    var singlepriceCheck = (rule, value, callback) => {
      console.log(value, 789);
      let minprices = this.ruleForm.RetailPrice.split("~")[0];
      let maxprices = this.ruleForm.RetailPrice.split("~")[1];
      if (!value || value == "") {
        return callback(new Error("请设置价格"));
      }
      if (
        (value < Number(minprices) || value > Number(maxprices)) &&
        this.priceEditdata.IsSupplierProduct
      ) {
        return callback(
          new Error(`售价请设置在${minprices}至${maxprices}之间`)
        );
      } else {
        return callback();
      }
    };
    return {
      specLoading: false,
      eidiTnames: "编辑名称与编码",
      goodsSorts: null,
      goodsSortsList: [
        {
          id: 1,
          value: "已发布到供货市场",
        },
        {
          id: 2,
          value: "分销商品",
        },
      ],
      virtualsalesTitle: "商品虚拟销量",
      productNameishow: true,
      virtualLoading: false,
      minFactor: null,
      maxFactor: null,
      virtualsalesNum: null,
      virtualsalesProduct: "",
      virtualsalesVisible: false,
      batchaddCarVisible: false,
      carruleForm: {
        IsAddCart: null,
      },
      sortNaming: false,
      weiCodeData: {},
      weiCodeShow: false,
      bathSortDatas: [],
      sortsLoading: false,
      sortSearchKey: "",
      eiditeSortVisible: false,
      mixListLoading: false,
      contacteditPriceVisable: false,
      contactLoading: false,
      ruleFormMoney: {
        EmployeeSellRewardType: 2,
        EmployeeSellRewardRate: null,
        RecommendSellRewardRate: null,
        MemberSellRewardType: 2,
        ProductSellRewardList: [],
      },
      moneyDialogShow: false,
      value: "",
      saleStylelist: [
        {
          id: 0,
          label: "普通商品",
        },
        {
          id: 1,
          label: "分销礼包",
        },
      ],
      saleStyle: null,
      SpecTitle: "",
      SpecTitle2: "",
      slectgoodNum: 0,
      isConcts: false,
      productNames: "",
      productSortList: [],
      productList: [],
      OrderBy: "",
      IsAsc: "",
      ruleForm: {
        name: "",
        code: "",
        singleprice: "",
        singledesc: "",
        specData: [],
      },
      contactRuleForm: {
        IsSetMixUnitPrice: 0,
        ProductMixList: [],
        contactOnePrice: null,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入商品名称",
            trigger: "blur",
          },
          // {
          // 	min: 1,
          // 	max: 100,
          // 	message: '最多输入100个汉字',
          // 	trigger: 'blur'
          // }
        ],
        Price: {
          required: true,
          validator: PriceCheck,
          trigger: "blur",
        },
        singleprice: {
          required: true,
          validator: singlepriceCheck,
          trigger: "blur",
        },
        IsAddCart: {
          required: true,
          message: "请选择开启或关闭加入购物车功能",
          trigger: "change",
        },
        code: [
          {
            min: 0,
            max: 50,
            message: "最多输入50个字符",
            trigger: "blur",
          },
        ],
        SellRewardMoney: {
          validator: checkSellRewardMoney,
          trigger: "blur",
        },
        RecommendSellRewardMoney: {
          validator: checkRecommendSellRewardMoney,
          trigger: "blur",
        },
        nocheck: [
          {
            validator: checkno,
            trigger: "change",
          },
        ],
        UnitPrice: {
          validator: checkUnitPrice,
          trigger: "blur",
          type: "number",
        },
        ProductCount: {
          validator: checkProductCount,
          trigger: "blur",
          type: "number",
        },
        contactOnePrice: [
          {
            required: true,
            message: "请输入价格",
            trigger: "blur",
          },
        ],
      },
      goodsSelect: [
        {
          id: 0,
          type: "单品",
        },
        {
          id: 1,
          type: "组合商品",
        },
      ],
      listStateList: [
        {
          id: 1,
          value: "销售中",
        },
        {
          id: 2,
          value: "已售罄",
        },
        {
          id: 3,
          value: "仓库中",
        },
      ],
      saleSelect: [
        {
          id: 0,
          type: "单品销售",
        },
        {
          id: 1,
          type: "组合销售",
        },
      ],
      currentItemGoods: "",
      roleList: [],
      listState: "1",
      groupData: [],
      ProductSpecId: "",
      stockData: [],
      currentPage: 1,
      pageSize: 20,
      Total: 3,
      checked: false,
      searchKey: "",
      searchType: 0,
      saleType: "", //销售类型
      goodsType: [], //商品分组
      brandType: "", //商品品牌
      priceMin: "",
      priceMax: "",
      saleMin: "",
      saleMax: "",
      multipleSelection: [],
      loading: false,
      editNameVisable: false, //是否显示名称编辑弹窗
      editPriceVisable: false, //是否显示价格编辑弹窗
      stockVisble: false, //是否显示库存弹窗
      productName: "",
      productNumber: "",
      deleteDialogShow: false,
      isCurrentAll: false,
      goodsDelect: {},
      btnLoading: false,
      baseAPI: config.BASE_URL,
      imgUrl: config.IMG_BASE,
      exportUrl: config.EXPORT_URL,
      priceEditdata: {},
      productEditdata: {},
      delecType: null,
      pricestocktype: null,
      currentnum: "",
      addProductShow: true,
      downloadProductShow: true,
      batchProductIds: [],
      goUrls: config.GO_URL,
      VisalProductId: null,
      virtualsalesType: null,
      comparePrice: null,
      changeloading: false,
      ishowIsOpenSupplierProduct: false,
      IsSupplierProduct: false,
      IsRecentUnsalableProduct: null,
    };
  },

  beforeMount() {

    this.goodsType = this.$route.query.ProductGroupId || "";
    this.brandType = this.$route.query.ProductBrandId || "";
    this.IsRecentUnsalableProduct = this.$route.query.IsRecentUnsalableProduct
      ? this.$route.query.IsRecentUnsalableProduct
      : null;
    this.ishowIsOpenSupplierProduct =
      window.localStorage.getItem("IsOpenSupplierProduct") == "true"
        ? true
        : false;

    console.log(this.ishowIsOpenSupplierProduct, " 是否开启到供货市场");
    this.getInfor();
    this.getList();
    this.getroleData();
  },
  methods: {
    //素材管理
    MaterialManage(item) {
      this.$router.push({
        path: "/goods/MeterialManagelist",
        query: {
          ProductId: item.ProductId ? item.ProductId : 0,
        },
      });
    },
    speceKey(row) {
      return row.ProductSpecId;
    },
    // 虚拟销量初始化
    async getVisal(record) {
      try {
        let result = await changeVirtualSalesInit({
          ProductId: record.ProductId,
        });
        this.virtualsalesNum = result.Result.VirtualSales;
        this.minFactor = result.Result.VirtualSalesCoefficientMin;
        this.maxFactor = result.Result.VirtualSalesCoefficientMax;
      } catch (e) {
        //TODO handle the exception
      } finally {
        this.virtualsalesVisible = true;
      }
    },
    // 虚拟销量
    setVisal(record) {
      this.productNameishow = true;
      this.virtualsalesType = 1;
      this.virtualsalesTitle = "商品虚拟销量";
      this.virtualsalesProduct = record.Name;
      this.VisalProductId = record.ProductId;
      this.getVisal(record);
    },
    // 批量设置虚拟销量
    bathVisale() {
      if (this.multipleSelection.length < 1) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择的商品!",
        });
        return;
      }
      this.virtualsalesType = 2;
      this.virtualsalesTitle = "批量设置商品虚拟销量";
      this.batchProductIds = [];
      this.multipleSelection.map((record) => {
        this.batchProductIds.push(record.ProductId);
      });
      this.productNameishow = false;
      this.virtualsalesVisible = true;
    },
    // 设置虚拟销量
    async virtualSave() {
      try {
        if (this.minFactor > this.maxFactor) {
          this.$message({
            showClose: true,
            type: "error",
            message: "最小系数要小于等于最大系数!",
          });
          return;
        }
        if (this.minFactor > 999 || this.maxFactor > 999) {
          this.$message({
            showClose: true,
            type: "error",
            message: "最大系数999!",
          });
          return;
        }

        let result = {};
        // 单个设置虚拟销量
        if (this.virtualsalesType == 1) {
          let data = {
            ProductId: this.VisalProductId,
            VirtualSales: this.virtualsalesNum,
            VirtualSalesCoefficientMin: this.minFactor,
            VirtualSalesCoefficientMax: this.maxFactor,
          };
          result = await changeVirtualSales(data);
        } else {
          // 批量设置虚拟销量
          let data1 = {
            ProductIds: this.batchProductIds,
            VirtualSales: this.virtualsalesNum,
            VirtualSalesCoefficientMin: this.minFactor,
            VirtualSalesCoefficientMax: this.maxFactor,
          };
          result = await batchChangeVirtualSales(data1);
        }

        if (result.IsSuccess) {
          this.$message({
            showClose: true,
            type: "success",
            message: "操作成功!",
          });
          this.getList();
          this.virtualsalesVisible = false;
        }
      } catch (e) {
        //TODO handle the exception
      } finally {
        this.virtualLoading = false;
      }
    },
    // 关闭虚拟销量弹框
    virtualsaleClose() {
      this.virtualsalesProduct = "";
      this.virtualsalesNum = null;
      this.minFactor = null;
      this.maxFactor = null;
    },
    // 加入购物车
    addcarBatchs() {
      if (this.multipleSelection.length < 1) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择的商品!",
        });
        return;
      }
      this.batchProductIds = [];
      this.multipleSelection.map((record) => {
        this.batchProductIds.push(record.ProductId);
      });
      this.batchaddCarVisible = true;
    },
    // 确定
    sureCarfun(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.sortsLoading = true;
            let data = {
              IsAddCart: this.carruleForm.IsAddCart,
              ProductIds: this.batchProductIds,
            };

            let result = await productBatchAddCart(data);
            if (result.IsSuccess) {
              this.$message({
                showClose: true,
                type: "success",
                message: "操作成功!",
              });
              this.getList();
              this.batchaddCarVisible = false;
            }
          } catch (e) {
            //TODO handle the exception
          } finally {
            this.sortsLoading = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 推广
    weiCodeFun(ProductId) {
      this.weiCodeShow = true;
      this.weiCodeData = {
        Page: `pages/detail/detail`,
        Scene: `id=${ProductId}`,
      };
    },
    weiCodeCancel(value) {
      this.weiCodeShow = value;
    },
    // 商品分组初始化
    async batchGroupInt() {
      try {
        this.sortNaming = true;
        let data = {
          ProductIds: this.batchProductIds,
          KeyWord: this.sortSearchKey,
        };
        let result = await batchChangeGroupInit(data);
        this.bathSortDatas = [];
        result.Result.Results.map((item) => {
          let obj = {
            GroupName: item.GroupName,
            ProductGroupId: item.ProductGroupId,
            ProductIds: item.ProductIds,
            SelectState: item.SelectState,
          };

          if (item.SelectState == 1) {
            // 所有商品均选中
            (obj.isIndeterminate = false), (obj.checkAll = true);
            console.log(item, 6998);
          } else if (item.SelectState == 2) {
            // 部分商品选中
            (obj.isIndeterminate = true), (obj.checkAll = false);
          } else if (item.SelectState == 3) {
            // 所有商品都不选中
            (obj.isIndeterminate = false), (obj.checkAll = false);
          }
          this.bathSortDatas.push(obj);
        });
      } catch (e) {
        //TODO handle the exception
        console.log(e);
      } finally {
        this.sortNaming = false;
      }
    },

    handleCheckAllChange(val) {
      this.bathSortDatas.map((item) => {
        if (item.ProductGroupId == val.ProductGroupId) {
          item.isIndeterminate = false;
        }
        return item;
      });
    },
    // 设置分组
    sortsBatchs() {
      if (this.multipleSelection.length < 1) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择的商品!",
        });
        return;
      }
      this.batchProductIds = [];
      this.multipleSelection.map((record) => {
        this.batchProductIds.push(record.ProductId);
      });
      this.batchGroupInt();
      this.eiditeSortVisible = true;
    },
    // 查询分组
    sortsfunFilter() {
      this.batchGroupInt();
    },

    codeChangefun() {
      this.ruleForm.code = this.ruleForm.code.replace(/[\W]/g, "");
    },
    keyFun1(row) {
      return row.ProductSpecId;
    },
    // 确认修改分组
    async sureEditSortfun() {
      try {
        this.sortsLoading = true;
        let ProductGroupIds = [];
        this.bathSortDatas.map((item) => {
          if (item.checkAll) {
            let obj = {
              ProductGroupId: item.ProductGroupId,
              ProductIds: this.batchProductIds,
            };
            ProductGroupIds.push(obj);
          }

          if (item.isIndeterminate) {
            let obj1 = {
              ProductGroupId: item.ProductGroupId,
              ProductIds: item.ProductIds,
            };
            ProductGroupIds.push(obj1);
          }
        });
        let data = {
          ProductIds: this.batchProductIds,
          ProductGroups: ProductGroupIds,
        };

        let result = await batchChangeGroup(data);
        if (result.IsSuccess) {
          this.$message({
            showClose: true,
            type: "success",
            message: "操作成功!",
          });
          this.getList();
          this.eiditeSortVisible = false;
        }
      } catch (e) {
        //TODO handle the exception
        console.log(e);
      } finally {
        this.sortsLoading = false;
      }
    },
    // 价格设置
    pricesetChange() {
      if (
        this.contactRuleForm.ProductMixList.length > 0 &&
        this.contactRuleForm.IsSetMixUnitPrice == 0
      ) {
        this.contactRuleForm.ProductMixList.map((item) => {
          item.UnitPrice = null;
        });
        this.contactRuleForm.contactOnePrice = null;
        this.$forceUpdate();
      }
    },
    salePriceFun() {
      this.priceFunction();
    },
    // 组合数量的变化
    contactChange() {
      if (this.contactRuleForm.IsSetMixUnitPrice == 1) {
        this.priceFunction();
      }
    },
    savecontacBtn(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.contactLoading = true;
          try {
            let ProductPriceList = [];
            let obj = {
              ProductSpecId: this.ProductSpecId,
              Price: this.contactRuleForm.contactOnePrice,
            };
            ProductPriceList.push(obj);
            let data = {
              ProductId: this.priceEditdata.ProductId,
              ProductPriceList: ProductPriceList,
              IsSetMixUnitPrice: this.contactRuleForm.IsSetMixUnitPrice
                ? true
                : false,
              ProductMixList: this.contactRuleForm.ProductMixList,
            };

            let result = await productChangePrice(data);
            if (result.IsSuccess) {
              this.$message({
                showClose: true,
                type: "success",
                message: "操作成功!",
              });
              this.contacteditPriceVisable = false;
            }
          } catch (e) {
            console.log(e);
          } finally {
            this.getList();
            this.contactLoading = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 计算组合商品价格
    priceFunction() {
      this.contactRuleForm.contactOnePrice = 0;
      this.contactRuleForm.ProductMixList.map((item) => {
        this.contactRuleForm.contactOnePrice =
          this.contactRuleForm.contactOnePrice +
          item.UnitPrice * item.ProductCount;
      });
      this.contactRuleForm.contactOnePrice =
        Math.floor(this.contactRuleForm.contactOnePrice * 100) / 100;

      this.$forceUpdate();
    },
    async saveMoneyFun() {
      try {
        let ProductIds = [];
        this.multipleSelection.map((item) => {
          ProductIds.push(item.ProductId);
        });

        let data = {
          ProductIds: ProductIds,
          EmployeeSellRewardType: this.ruleFormMoney.EmployeeSellRewardType,
          EmployeeSellRewardMoney: this.ruleFormMoney.EmployeeSellRewardMoney,
          EmployeeSellRewardRate: this.ruleFormMoney.EmployeeSellRewardRate,
          MemberSellRewardType: this.ruleFormMoney.MemberSellRewardType,
          ProductSellRewardList: this.ruleFormMoney.ProductSellRewardList,
        };
        let reuslt = await changeSellReward(data);
        if (reuslt.IsSuccess) {
          this.$message({
            showClose: true,
            type: "success",
            message: "操作成功!",
          });
          this.moneyDialogShow = false;
        }
      } catch (e) {
        //TODO handle the exception
        console.log(e);
      } finally {
        this.getList();
      }
    },

    // 收益弹框关闭
    moneyClosefun() {
      this.ruleFormMoney = {
        EmployeeSellRewardType: 2,
        EmployeeSellRewardRate: null,
        RecommendSellRewardRate: null,
        MemberSellRewardType: 2,
        ProductSellRewardList: [],
      };
      this.getroleData();
    },
    // 批量设置收益
    moneyBatchs() {
      if (this.multipleSelection.length < 1) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择操作的商品!",
        });
        return;
      }
      this.moneyDialogShow = true;
    },
    // 单个改变商品在供货市场的状态
    async singleChangMark(record, type) {
      try {
        this.changeloading = true;
        let data = {
          ProductId: record.ProductId,
          IsOpenSupplierProduct: type,
        };
        let result = await changeSupplierState(data);
        if (result.IsSuccess) {
          this.$message({
            showClose: true,
            type: "success",
            message: "操作成功!",
          });
        }
      } catch (e) {
        //TODO handle the exception
      } finally {
        this.getList();
        this.changeloading = false;
      }
    },
    // 批量改变商品在供货市场的状态
    async changeMark(type) {
      if (this.multipleSelection.length < 1) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择操作的商品!",
        });
        return;
      }
      try {
        this.changeloading = true;
        let productIds = [];
        this.multipleSelection.map((item) => {
          productIds.push(item.ProductId);
        });
        let data = {
          ProductIds: productIds,
          IsOpenSupplierProduct: type,
        };

        let result = await batchChangeSupplierState(data);

        if (result.IsSuccess) {
          this.$message({
            showClose: true,
            type: "success",
            message: "操作成功!",
          });
        }
      } catch (e) {
        //TODO handle the exception
        console.log(e);
      } finally {
        this.getList();
        this.changeloading = false;
      }
    },

    // 获取推手收益列表
    async getroleData() {
      try {
        let result2 = await distributorRoleFilterBoxList();
        result2.Result.map((item) => {
          let obj = {
            Id: 0,
            DistributRoleName: item.DistributRoleName,
            MemberDistributorRoleId: item.Id,
            SellRewardMoney: null,
            SellRewardRate: "",
            RecommendSellRewardMoney: null,
            RecommendSellRewardRate: "",
          };
          this.ruleFormMoney.ProductSellRewardList.push(obj);
        });
      } catch (e) {
        //TODO handle the exception
        console.log(e);
      } finally {
        console.log("finally");
      }
    },
    mousereeEnter(row, column, cell, event) {
      console.log(event);
      this.groupData.map((item, index) => {
        if (item.ProductId == row.ProductId) {
          this.$set(this.groupData[index], "isShoweite", true);
        }
        return item;
      });
      this.groupData.reverse().reverse();
    },
    mouserLeave(row, column, cell, event) {
      console.log(event);
      this.groupData.map((item, index) => {
        if (item.ProductId == row.ProductId) {
          this.$set(this.groupData[index], "isShoweite", false);
        }
        return item;
      });
      this.groupData.reverse().reverse();
    },

    //筛选数据
    async getInfor() {
      try {
        let result = await productBrandList();
        this.productList = result.Result;

        let result1 = await productGroupList();
        this.productSortList = result1.Result;
      } catch (e) {
        console.log(e);
      } finally {
        console.log("finally");
      }
    },
    keyTypeChange() {
      if (this.searchType == 0) {
        this.saleType = 0;
      } else {
        this.saleType = 1;
      }
    },
    saleChanges() {
      if (this.saleType == 0) {
        this.searchType = 0;
      } else if (this.saleType == 1) {
        this.searchType = 1;
      }
    },
    handleInput2(e) {
      // 通过正则过滤小数点后两位
      e.target.value = e.target.value.match(/^\d*(\.?\d{0,1})/g)[0] || null;
    },

    // 排序
    sortChange(column, prop, order) {
      console.log(order);
      this.OrderBy = column.prop;
      if (column.order == "ascending") {
        // 正序
        this.IsAsc = true;
      } else {
        this.IsAsc = false;
      }
      this.currentPage = 1;
      this.getList();
    },

    // 获取商品列表
    async getList() {
      try {
        this.loading = true;
        let data = {
          IsRecentUnsalableProduct: this.IsRecentUnsalableProduct,
          IsAuthGift: this.saleStyle,
          ProductState: this.listState,
          KeyWord: this.searchKey, // 搜索关键字
          KeyWordType: this.searchType,
          ProductType: this.saleType,
          ProductGroupIds: this.goodsType.includes(-1) ? "" : this.goodsType,
          ProductBrandId: this.brandType, // 品牌id
          PriceMin: this.priceMin,
          PriceMax: this.priceMax,
          SellMin: this.saleMin,
          SellMax: this.saleMax,
          ProductGenre: this.goodsSorts,
          Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
          Take: this.pageSize, // 取的数据
          OrderBy: this.OrderBy,
          IsAsc: this.IsAsc,
        };
        console.log("this.goodsType", this.goodsType, this.brandType);
        let result = await productList(data);
        this.Total = result.Result.Total;
        this.groupData = result.Result.Results || [];
        this.groupData.map((item) => {
          item.isShoweite = false;
        });
      } catch (e) {
        console.log(e);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
    //顶部tab切换事件
    filterFun(tab, event) {
      this.checked = false;
      console.log(event);
      this.goodsFilter();
    },
    // 搜索
    goodsFilter() {
      this.currentPage = 1;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.isCurrentAll = val.length == this.groupData.length ? true : false;
      if (this.isCurrentAll) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    tabCheckedChange() {
      this.$refs["multipleTable"].toggleAllSelection();
    },
    // 导出
    async exportFun() {
      this.loading = true;

      try {
        let url =
          this.exportUrl +
          "/pc/product/export?" +
          "&ProductState=" +
          this.listState +
          "&KeyWord=" +
          this.searchKey +
          "&KeyWordType=" +
          this.searchType +
          "&ProductType=" +
          (this.saleType == null && this.saleType != 0 ? "" : this.saleType) +
          "&ProductGroupIds=" +
          (this.goodsType.includes(-1) ? "" : this.goodsType) +
          "&OrderBy=" +
          this.OrderBy +
          "&IsAsc=" +
          this.IsAsc +
          "&ProductBrandId=" +
          this.brandType +
          "&ProductGenre=" +
          (this.goodsSorts ? this.goodsSorts : "") +
          "&PriceMin=" +
          this.priceMin +
          "&PriceMax=" +
          this.priceMax +
          "&SellMin=" +
          this.saleMin +
          "&SellMax=" +
          this.saleMax;

        window.open(url);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    //批量删除
    deleteBatchs() {
      if (this.multipleSelection.length < 1) {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择操作的商品!",
        });
        return;
      }
      this.delecType = 2;
      this.slectgoodNum = this.multipleSelection.length;
      this.currentItemGoods = "";

      this.deleteDialogShow = true;
    },

    async doublesDelect() {
      try {
        let ProductIdList = [];
        this.multipleSelection.map((item) => {
          ProductIdList.push(item.ProductId);
        });

        let result = await productBatchDelete({
          ProductIdList: ProductIdList,
        });
        console.log(result, "批量删除");
        if (result.IsSuccess) {
          this.$message({
            showClose: true,
            type: "success",
            message: "操作成功!",
          });
          this.deleteDialogShow = false;
        } else {
          this.currentItemGoods = result.Result;
          this.currentnum = result.Message;
        }
      } catch (e) {
        this.currentItemGoods = e;
        console.log(e);
      } finally {
        this.getList();
      }
    },

    //批量上架
    async addBatchs(type) {
      try {
        if (this.multipleSelection.length < 1) {
          this.$message({
            showClose: true,
            type: "error",
            message: "请选择操作的商品!",
          });
          return;
        }
        let ProductIdList = [];
        this.multipleSelection.map((item) => {
          ProductIdList.push(item.ProductId);
        });
        let data = {
          ProductIdList: ProductIdList,
          IsOpen: type == 1 ? false : true,
        };
        let result = await productBatchOpen(data);
        if (result.IsSuccess) {
          this.$message({
            showClose: true,
            type: "success",
            message: "操作成功!",
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.goodsFilter();
      }
    },
    // 切换显示条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getList();
    },

    // 翻页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    productEdit(item) {
      this.IsSupplierProduct = item.IsSupplierProduct;
      if (item.IsSupplierProduct) {
        this.eidiTnames = "编辑名称";
      } else {
        this.eidiTnames = "编辑名称与编码";
      }
      this.productEditdata = item;
      this.ruleForm.name = item.Name;
      this.ruleForm.code = item.ProductNo;
      this.editNameVisable = true;
    },
    // 编辑商品价格
    priceEdit(item) {
      this.SpecTitle = item.SpecTitle;
      this.SpecTitle2 = item.SpecTitle2;
      this.priceEditdata = item;
      this.pricestocktype = 1;
      this.getDataEdit();
      if (item.ProductType == 0) {
        this.editPriceVisable = true;
      } else {
        this.contacteditPriceVisable = true;
      }
    },
    stockEdit(item) {
      this.SpecTitle = item.SpecTitle;
      this.SpecTitle2 = item.SpecTitle2;
      this.priceEditdata = item;
      this.ruleForm.specData = [];
      this.getDataEdit();
      this.pricestocktype = 2;
      this.stockVisble = true;
    },

    // 多规格分销商品价格变化
    tablepriceChange(record) {
      this.comparePrice = record.RetailPriceMin;
      this.competeProft();
    },
    // 单规格价格变化
    sintablepriceChange() {
      this.singleCompeteProft();
    },

    //多规格计算利润
    competeProft() {
      this.ruleForm.specData.map((item) => {
        console.log(item);
        item.suppierProfit = (item.Price - item.RetailPriceMin).toFixed(2);
        return item;
      });
    },
    // 单规格计算利润
    singleCompeteProft() {
      let minPrice = this.ruleForm.RetailPrice.split("~")[0];
      this.ruleForm.suppierProfit = (
        Number(this.ruleForm.singleprice) - Number(minPrice)
      ).toFixed(2);
    },
    async getDataEdit() {
      try {
        this.specLoading = true;
        this.productNames = this.priceEditdata.Name;
        let result = await productSpecGet({
          ProductId: this.priceEditdata.ProductId,
        });
        let ProductSpecList = result.Result.ProductSpecList;
        if (
          ProductSpecList.length == 1 &&
          !ProductSpecList[0].SpecValue &&
          !ProductSpecList[0].SpecValue2
        ) {
          // this.ruleForm.specData=[];
          this.ProductSpecId = ProductSpecList[0].ProductSpecId;
          this.ruleForm = {
            name: this.ruleForm.singleprice,
            code: this.ruleForm.singleprice,
            singleprice: ProductSpecList[0].Price,
            singledesc: ProductSpecList[0].Stock,
            SupplierMallPrice: ProductSpecList[0].SupplierMallPrice,
            RetailPriceMin: ProductSpecList[0].RetailPriceMin,
            RetailPriceMax: ProductSpecList[0].RetailPriceMax,
            RetailPrice:
              ProductSpecList[0].RetailPriceMin +
              "~" +
              ProductSpecList[0].RetailPriceMax,
            suppierProfit:
              Number(ProductSpecList[0].Price) -
              Number(ProductSpecList[0].RetailPriceMin),
            specData: [],
          };
          this.singleCompeteProft();
        } else {
          this.ruleForm.specData = result.Result.ProductSpecList;
          this.competeProft();
        }

        if (this.priceEditdata.ProductType == 1) {
          this.mixListLoading = true;
          this.contactRuleForm.IsSetMixUnitPrice = result.Result
            .IsSetMixUnitPrice
            ? 1
            : 0;
          this.contactRuleForm.ProductMixList = [];
          result.Result.ProductMixList.map((item) => {
            let obj = {
              ProductMixId: item.ProductMixId,
              Name: item.Name,
              Spec: item.Spec,
              Price: item.Price,
              Stock: item.Stock,
              ProductCount: item.ProductCount ? item.ProductCount : null,
              UnitPrice: item.UnitPrice,
            };
            this.contactRuleForm.ProductMixList.push(obj);
          });

          this.contactRuleForm.contactOnePrice =
            result.Result.ProductSpecList[0].Price;
          if (result.Result.IsSetMixUnitPrice) {
            this.priceFunction();
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.specLoading = false;
        this.mixListLoading = false;
      }
    },
    // 保存编辑库存
    async savestockBtn() {
      try {
        this.btnLoading = true;
        let newSpeceData = [];
        let arryDatas = [];
        let stockArry = [];
        let isNegative = [];
        let outPricedata = [];
        let outStockdata = [];
        if (this.ruleForm.specData.length) {
          this.ruleForm.specData.map((item) => {
            if (Number(item.Price) < 0) {
              isNegative.push(item);
            }
            let obj = {
              ProductSpecId: item.ProductSpecId,
              Price: item.Price,
              Stock: item.Stock,
            };
            newSpeceData.push(obj);

            if (!item.Price && item.Price !== 0) {
              arryDatas.push(item);
            }
            if (item.Price > 100000000) {
              outPricedata.push(item);
            }
            if (!item.Stock && item.Stock !== 0) {
              stockArry.push(item);
            }
            if (item.Stock > 100000000) {
              outStockdata.push(item);
            }
          });
          if (stockArry.length && this.pricestocktype == 2) {
            this.$message({
              showClose: true,
              type: "error",
              message: "请完善库存信息!",
            });
            this.btnLoading = false;
            return;
          }
          if (outStockdata.length && this.pricestocktype == 2) {
            this.$message({
              showClose: true,
              type: "error",
              message: "商品库存不能超过100000000!",
            });
            this.btnLoading = false;
            return;
          }
        } else {
          if (!this.ruleForm.singledesc && this.pricestocktype == 2) {
            this.$message({
              showClose: true,
              type: "error",
              message: "请设置正确的库存!",
            });
            this.btnLoading = false;
            return;
          }
          if (
            this.ruleForm.singledesc &&
            this.pricestocktype == 2 &&
            this.ruleForm.singledesc > 100000000
          ) {
            this.$message({
              showClose: true,
              type: "error",
              message: "商品库存不能超过100000000!",
            });
            this.btnLoading = false;
            return;
          }
          let objs = {
            ProductSpecId: this.ProductSpecId,
            Price: this.ruleForm.singleprice,
            Stock: this.ruleForm.singledesc,
          };
          newSpeceData.push(objs);
        }

        let data1 = {
          ProductId: this.priceEditdata.ProductId,
          ProductStockList: newSpeceData,
        };
        let result = await productSpeChangeStock(data1);

        if (result.IsSuccess) {
          this.$message({
            showClose: true,
            type: "success",
            message: "操作成功!",
          });
          this.stockVisble = false;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.goodsFilter();
        this.btnLoading = false;
      }
    },

    // 保存编辑价格
    savePriceBtn(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.btnLoading = true;
            let newSpeceData = [];
            if (this.ruleForm.specData.length) {
              this.ruleForm.specData.map((item) => {
                let obj = {
                  ProductSpecId: item.ProductSpecId,
                  Price: item.Price,
                  Stock: item.Stock,
                };
                newSpeceData.push(obj);
              });
            } else {
              let objs = {
                ProductSpecId: this.ProductSpecId,
                Price: this.ruleForm.singleprice,
                Stock: this.ruleForm.singledesc,
              };
              newSpeceData.push(objs);
            }

            let data = {
              ProductId: this.priceEditdata.ProductId,
              ProductPriceList: newSpeceData,
            };

            let result = await productChangePrice(data);

            if (result.IsSuccess) {
              this.$message({
                showClose: true,
                type: "success",
                message: "操作成功!",
              });
              this.editPriceVisable = false;
            }
          } catch (e) {
            console.log(e);
          } finally {
            this.goodsFilter();
            this.btnLoading = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    eidtGroup(item) {
      this.isShowEdit = !this.isShowEdit;
      let productId = item.ProductId ? item.ProductId : 0;
      let headsUrls = process.env.NODE_ENV == "production" ? "index.html#" : "";
      let url =
        this.goUrls + headsUrls + "/goods/creatGoodDetails?Id=" + productId;
      window.open(url);

      // this.$router.push({
      // 	path: '/goods/creatGoodDetails',
      // 	query: {
      // 		Id: item.ProductId ? item.ProductId : 0
      // 	}
      // });
    },
    deleteChose(record) {
      this.delecType = 1;
      this.goodsDelect = record;

      this.slectgoodNum = 1;
      this.deleteDialogShow = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              ProductId: this.productEditdata.ProductId,
              Name: this.ruleForm.name,
              ProductNo: this.ruleForm.code,
            };

            let result = await productChangeNameAndNo(data);
            if (result.IsSuccess) {
              this.$message({
                showClose: true,
                type: "success",
                message: "操作成功!",
              });
              this.editNameVisable = false;
            }
          } catch (e) {
            console.log(e);
          } finally {
            this.goodsFilter();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    // 上下架
    async displyFun(record, type) {
      this.loading = true;
      try {
        let data = {
          ProductId: record.ProductId,
          IsOpen: type == 1 ? true : false,
        };
        let result = await productOpen(data);
        if (result.IsSuccess) {
          this.$message({
            showClose: true,
            type: "success",
            message: "操作成功!",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.currentPage = 1;
        this.getList();
      }
    },
    sureDelect() {
      if (this.delecType == 1) {
        this.singleDelect();
      } else {
        this.doublesDelect();
      }
    },

    // 确定删除商品
    async singleDelect() {
      try {
        this.btnLoading = true;
        let result = await productDelete({
          productId: this.goodsDelect.ProductId,
        });
        if (result.IsSuccess) {
          this.$message({
            showClose: true,
            type: "success",
            message: "删除成功!",
          });
          this.deleteDialogShow = false;
        }
      } catch (e) {
        if (Array.isArray(e)) {
          this.currentItemGoods = e;
        }
      } finally {
        this.getList();
        this.btnLoading = false;
      }
    },
  },
};
</script>

<style lang="less">
.virtualsalesBox {
  /deep/.el-dialog__body {
    padding: 0 20px 10px !important;
  }
}

.nameboxClass {
  .el-dialog__body {
    padding: 0;
  }
}

.ml-goodList {
  .product-info > img {
    width: 80px;
    height: 80px;
  }

  .setPricebos {
    .el-form-item {
      margin-bottom: 0;
    }
  }

  .el-dialog__body {
    padding: 10px 20px !important;
  }

  .bottom_delete {
    border: 1px solid #f5f5f5;
    margin-left: 10px;
    padding: 5px 13px;
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .number__input {
    border: 1px solid #ccc;
  }

  .pointers {
    cursor: pointer;
  }

  .sorts-names {
    max-height: 400px;
    overflow: auto;
    margin-top: 20px;
  }

  .sorttable-top {
    width: 100%;
    background: #f8f8f9;
    padding: 15px;
  }

  .sortTable-body {
    width: 100%;
    padding: 15px;
  }
}
</style>
